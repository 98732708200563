import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import ImageComponent from "../ui/Image";

import benefitImg from "../images/half.jpg";
import benefitImg1 from "../images/image.jpg";
import TextComponent from "../ui/Text";
import HeadingComponent from "../ui/Heading";

export default function AboutUs() {
  return (
    <Flex
      align="center"
      p={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      direction="column"
      gap="1rem"
      w="100%"
    >
      <Flex
        align="center"
        p={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
        justify="space-between"
        gap={{ base: "1rem", md: "1rem", lg: "0rem" }}
        wrap="wrap"
        w="100%"
      >
        <Flex
          w={{ base: "100%", md: "100%", lg: "50%" }}
          align="center"
          gap="1rem"
          display={{ base: "none", md: "none", lg: "flex" }}
        >
          <Box w="25%">
            <ImageComponent alt="benefit" src={benefitImg} height="636px" />
          </Box>
          <Box w="75%">
            <ImageComponent alt="benefit" src={benefitImg1} height="636px" />
          </Box>
        </Flex>
        <Flex
          p={{ base: "0.5rem", md: "0.5rem", lg: "2rem" }}
          height={{ base: "100%", md: "100%", lg: "636px" }}
          w={{ base: "100%", md: "100%", lg: "50%" }}
          align="start"
          justify="center"
          gap="2rem"
          direction="column"
        >
          <TextComponent
            title="About Us"
            color="brand.900"
            align="start"
            weight="bold"
            size="24px"
          />
          <HeadingComponent
            title="SteppingGlory Developer LLC TM"
            size={{ base: "30px", md: "30px", lg: "40px" }}
            color="#000"
            weight="bold"
            align="start"
            width={{ base: "90%", md: "90%", lg: "70%" }}
          />

          <TextComponent
            title="We are a tech agency that specializes in website design, development, mobile app development, SEO, digital marketing, and content creation. We are a team of experienced professionals who are passionate about helping our clients succeed.
            "
            color="#000"
            align="start"
            weight="normal"
            size="20px"
          />
          <TextComponent
            title="We understand that every business is unique, and we tailor our services to meet the specific needs of each client. We work with our clients to understand their goals, and we develop a plan to help them achieve those goals.
            "
            color="#000"
            align="start"
            weight="normal"
            size="20px"
          />
          <TextComponent
            title="We use the latest technologies and best practices to create high-quality, user-friendly websites and mobile apps. We also offer a wide range of digital marketing services, including SEO, PPC, social media marketing, and email marketing.
            "
            color="#000"
            align="start"
            weight="normal"
            size="20px"
          />
        </Flex>
      </Flex>
      <Flex
        p={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
        align="start"
        direction="column"
        gap="2rem"
      >
        <TextComponent
          title="We are committed to providing our clients with the best possible service. We are always available to answer questions and provide support. We also offer a satisfaction guarantee on all of our services.
          "
          color="#000"
          align="start"
          weight="normal"
          size="20px"
        />

        <TextComponent
          title="If you are looking for a tech agency that can help you take your business to the next level, then contact SteppingGlory Developer LLC TM today. We would be happy to discuss your needs and how we can help you achieve your goals.
          "
          color="#000"
          align="start"
          weight="normal"
          size="20px"
        />

        <HeadingComponent
          title="Our Commitment"
          size="30px"
          color="#000"
          weight="bold"
          align="center"
        />

        <TextComponent
          title="We are committed to providing our clients with the best possible service. We are always available to answer questions and provide support. We also offer a satisfaction guarantee on all of our services."
          color="#000"
          align="start"
          weight="normal"
          size="20px"
        />

        <HeadingComponent
          title="Our Team"
          size="30px"
          color="#000"
          weight="bold"
          align="center"
        />

        <TextComponent
          title="Our team is composed of experienced professionals who are passionate about helping our clients succeed. We have a proven track record of delivering high-quality, user-friendly websites and mobile apps. We also offer a wide range of digital marketing services, including SEO, PPC, social media marketing, and email marketing."
          color="#000"
          align="start"
          weight="normal"
          size="20px"
        />
      </Flex>
    </Flex>
  );
}
