import { Image } from "@chakra-ui/react";

export default function ImageComponent({ src, alt, height }) {
  return (
    <Image
      boxSize="100%"
      objectFit="cover"
      src={src}
      alt={alt}
      height={height}
    />
  );
}
