import React, { Fragment } from "react";
import Header from "../ui/Header";
import Hero from "./Hero";
import AboutUs from "./AboutUs";
import OurServices from "./OurServices";
import Benefit from "./Benefit";
import Testimony from "./Testimony";
import Choose from "./Choose";
import Footer from "../ui/Footer";
import { useDisclosure } from "@chakra-ui/react";

export default function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Fragment>
      <Header isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Hero />
      <AboutUs />
      <OurServices />
      <Benefit />
      <Testimony />
      <Choose />
      <Footer />
    </Fragment>
  );
}
