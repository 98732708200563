import React from "react";
import { Heading } from "@chakra-ui/react";

export default function HeadingComponent({
  title,
  size,
  color,
  weight,
  align,
  width,
  mx,
}) {
  return (
    <Heading
      fontSize={size ? size : "14px"}
      color={color ? color : ""}
      fontWeight={weight ? weight : "medium"}
      textAlign={align ? align : "center"}
      width={width ? width : ""}
      mx={mx ? mx : ""}
    >
      {title}
    </Heading>
  );
}
