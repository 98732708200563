import {
  Flex,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import React, { Fragment } from "react";
import ImageComponent from "./Image";
import logo from "../images/logo.png";
import work from "../images/work.pdf";

import { NavLink, useNavigate } from "react-router-dom";
import ButtonComponent from "./Button";
import Contact from "../contact/Contact";
import { AiOutlineMenu } from "react-icons/ai";

export default function Header({ isOpen, onOpen, onClose }) {
  const navigate = useNavigate();
  return (
    <Fragment>
      <Flex
        w="100%"
        bg="#F5F5F5"
        py="0.5rem"
        align="center"
        justify="space-between"
        px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      >
        <Box
          onClick={() => navigate("/")}
          w={{ base: "120px", md: "120px", lg: "150px" }}
        >
          <ImageComponent alt="logo" src={logo} height="100%" />
        </Box>
        <Box display={{ base: "block", md: "block", lg: "none" }}>
          <Menu>
            <MenuButton bg="transparent" as={Button}>
              <AiOutlineMenu fontSize="20px" />
            </MenuButton>
            <MenuList>
              <MenuItem>
                <NavLink to="/" className="menu">
                  Home
                </NavLink>
              </MenuItem>
              <MenuItem>
                {" "}
                <NavLink to="/about" className="menu">
                  About Us
                </NavLink>
              </MenuItem>
              <MenuItem>
                {" "}
                <NavLink to="/services" className="menu">
                  Services
                </NavLink>
              </MenuItem>
              <MenuItem>
                {" "}
                <a
                  style={{
                    color: "#858585",
                    fontSize: "16px",
                    fontWeight: "medium",
                  }}
                  href={work}
                  download
                >
                  Our Works
                </a>
              </MenuItem>
              <MenuItem>
                <ButtonComponent onClick={onOpen} title="Start a Project" />
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Flex
          display={{ base: "none", md: "none", lg: "flex" }}
          align="center"
          gap="3rem"
        >
          <Flex align="center" gap="2rem">
            <NavLink to="/" className="menu">
              Home
            </NavLink>
            <NavLink to="/about" className="menu">
              About Us
            </NavLink>
            <NavLink to="/services" className="menu">
              Services
            </NavLink>

            <a
              style={{
                color: "#858585",
                fontSize: "16px",
                fontWeight: "medium",
              }}
              href={work}
              download
            >
              Our Works
            </a>
          </Flex>
          <ButtonComponent onClick={onOpen} title="Start a Project" />
        </Flex>
      </Flex>
      <Contact isOpen={isOpen} onClose={onClose} />
    </Fragment>
  );
}
