import { Text } from "@chakra-ui/react";

export default function TextComponent({
  title,
  size,
  color,
  weight,
  align,
  width,
  mx,
}) {
  return (
    <Text
      fontSize={size ? size : "14px"}
      color={color ? color : ""}
      fontWeight={weight ? weight : "medium"}
      textAlign={align ? align : "center"}
      width={width ? width : ""}
      mx={mx ? mx : ""}
    >
      {title}
    </Text>
  );
}
