import { FormControl, FormLabel, Input } from "@chakra-ui/react";

export default function InputComponent({
  label,
  value,
  onChange,
  placeholder,
  type,
}) {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Input
        placeholder={placeholder}
        focusBorderColor="brand.900"
        value={value}
        onChange={onChange}
        type={type ? type : "email"}
      />
    </FormControl>
  );
}
