import { Box, Flex } from "@chakra-ui/react";

import ImageComponent from "../ui/Image";
import heroImg from "../images/aboutpage.jpg";

import HeadingComponent from "../ui/Heading";

export default function Hero() {
  return (
    <Box position="relative" w="100%">
      <ImageComponent
        src={heroImg}
        height={{ base: "50vh", md: "50vh", lg: "70vh" }}
        alt="hero image"
      />
      <Flex
        align="center"
        justify="center"
        position="absolute"
        top="0%"
        height="100%"
        w="100%"
      >
        <Flex align="center" direction="column" gap="2rem">
          <Flex align="center" gap="1rem" direction="column">
            <HeadingComponent
              title="Our Services "
              weight="bold"
              size={{ base: "20px", md: "20px", lg: "64px" }}
              color="white"
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
