import { Flex } from "@chakra-ui/react";
import React from "react";
import HeadingComponent from "../ui/Heading";
import TextComponent from "../ui/Text";

export default function Choose() {
  return (
    <Flex
      align="center"
      p={{ base: "1rem", md: "1rem", lg: "3rem" }}
      bg="#D6D6D6"
      direction="column"
      gap={{ base: "1rem", md: "1rem", lg: "3rem" }}
    >
      <HeadingComponent
        align="center"
        size="24px"
        weight="bold"
        title="Why Business Choose US"
        color="brand.900"
      />
      <HeadingComponent
        align="center"
        size={{ base: "20px", md: "20px", lg: "40px" }}
        weight="bold"
        title="Jumpstart Your Business"
        color="#000"
      />
      <TextComponent
        color="#000"
        title="SteppingGlory Developer LLC TM is a leading digital marketing agency that helps businesses of all sizes achieve their online goals. We have a team of experienced professionals who are experts in their field. We use the latest technologies and best practices to create high-quality solutions. We are committed to providing our clients with the best possible service.
        strategy to"
        weight="normal"
        size={{ base: "14px", md: "14px", lg: "20px" }}
        align={{ base: "start", md: "start", lg: "center" }}
        width={{ base: "100%", md: "100%", lg: "60%" }}
        mx="auto"
      />
      <Flex
        w="full"
        py="3rem"
        wrap="wrap"
        align="center"
        justify="space-around"
        gap="1rem"
      >
        <Flex
          height={{ base: "100%", md: "100%", lg: "250px" }}
          w={{ base: "100%", md: "100%", lg: "30%" }}
          align="center"
          direction="column"
          gap="1rem"
        >
          <HeadingComponent
            align="center"
            size={{ base: "16px", md: "16px", lg: "24px" }}
            weight="bold"
            title="We're loyal"
            color="#000"
          />
          <TextComponent
            color="#000"
            title="We are committed to our customers and partners. We believe in building long-term relationships based on trust and mutual respect. We are always looking for ways to improve our services and exceed our customers' expectations.
            "
            weight="normal"
            size={{ base: "14px", md: "14px", lg: "20px" }}
            align={{ base: "start", md: "start", lg: "center" }}
            width="80%"
            mx="auto"
          />
        </Flex>

        <Flex
          height={{ base: "100%", md: "100%", lg: "250px" }}
          w={{ base: "100%", md: "100%", lg: "30%" }}
          align="center"
          direction="column"
          gap="1rem"
        >
          <HeadingComponent
            align="center"
            size={{ base: "16px", md: "16px", lg: "24px" }}
            weight="bold"
            title="We're helpful"
            color="#000"
          />
          <TextComponent
            color="#000"
            title="We are a team of experts who are passionate about helping our customers succeed. We offer a wide range of services and solutions, and we are always willing to go the extra mile to make sure that our customers are satisfied.
            "
            weight="normal"
            size={{ base: "14px", md: "14px", lg: "20px" }}
            align={{ base: "start", md: "start", lg: "center" }}
            width="80%"
            mx="auto"
          />
        </Flex>

        <Flex
          height={{ base: "100%", md: "100%", lg: "250px" }}
          w={{ base: "100%", md: "100%", lg: "30%" }}
          align="center"
          direction="column"
          gap="1rem"
        >
          <HeadingComponent
            align="center"
            size={{ base: "16px", md: "16px", lg: "24px" }}
            weight="bold"
            title="We're flexible"
            color="#000"
          />
          <TextComponent
            color="#000"
            title="We understand that every business is different. We are flexible and adaptable, and we are always willing to work with our customers to find the best solution for their needs. We are also committed to providing our customers with the best possible value for their money."
            weight="normal"
            size={{ base: "14px", md: "14px", lg: "20px" }}
            align={{ base: "start", md: "start", lg: "center" }}
            width="80%"
            mx="auto"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
