import { Flex, Box } from "@chakra-ui/react";
import TextComponent from "../ui/Text";
import HeadingComponent from "../ui/Heading";
import ButtonComponent from "../ui/Button";
import aboutImage from "../images/african.jpg";
import ImageComponent from "../ui/Image";
import { useNavigate } from "react-router-dom";

export default function AboutUs() {
  const navigate = useNavigate();
  return (
    <Flex
      w="100%"
      my="4rem"
      align="start"
      p={{ base: "1rem", md: "1rem", lg: "2.5rem" }}
      gap={{ base: "0.5rem", md: "0.5rem", lg: "1rem" }}
      direction="column"
    >
      <TextComponent
        color="brand.900"
        weight="bold"
        size="24px"
        title="About Us"
        align="start"
      />
      <HeadingComponent
        size={{ base: "16px", md: "16px", lg: "35px" }}
        weight="bold"
        title="We stand as one of the leading technical agency"
        width={{ base: "80%", md: "80%", lg: "40%" }}
        color="#000"
        align="start"
      />
      <Flex
        position="relative"
        direction={{ base: "column", md: "column", lg: "row" }}
        align="center"
        justify="space-between"
        w="100%"
      >
        <Box w={{ base: "100%", md: "100%", lg: "50%" }}>
          <ImageComponent
            src={aboutImage}
            alt="about us"
            height={{ base: "40vh", md: "40vh", lg: "614px" }}
          />
        </Box>
        <Flex
          align="start"
          p={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
          height="614px"
          direction="column"
          gap="1rem"
          w={{ base: "100%", md: "100%", lg: "50%" }}
        >
          <TextComponent
            size="20px"
            align="start"
            color="#000"
            weight="normal"
            title="We are a leading technical agency that specializes in web development, mobile app development, and digital marketing. We have a team of experienced professionals who are experts in their field."
          />
          <TextComponent
            size="20px"
            color="#000"
            align="start"
            weight="normal"
            title="We use the latest technologies and best practices to create high-quality solutions."
          />
          <TextComponent
            size="20px"
            color="#000"
            align="start"
            weight="normal"
            title="We are committed to providing our clients with the best possible service"
          />
          <ButtonComponent
            title="Read more"
            variant="flushed"
            color="brand.900"
            bg="transparent"
            onClick={() => navigate("/about")}
          />
        </Flex>
        <Flex
          position="absolute"
          left={{ base: "0%", md: "0%", lg: "30%" }}
          bottom="-10%"
          w={{ base: "100%", md: "100%", lg: "660px" }}
          height={{ base: "200px", md: "200px", lg: "260px" }}
          align="center"
          justify="space-between"
          bg="brand.900"
          p={{ base: "1rem", md: "1rem", lg: "2.5rem" }}
        >
          <Flex
            align={{ base: "center", md: "center", lg: "start" }}
            direction="column"
            gap="1rem"
            w="50%"
          >
            <TextComponent
              title="Clients base"
              size={{ base: "20px", md: "20px", lg: "24px" }}
              color="white"
              weight="bold"
              align="start"
            />
            <HeadingComponent
              title="24+"
              size={{ base: "30px", md: "30px", lg: "48px" }}
              color="white"
              weight="bold"
              align="start"
            />
            <TextComponent
              title="happy clients so far"
              size="14px"
              color="white"
              weight="normal"
              align="start"
            />
          </Flex>

          <Flex
            align={{ base: "center", md: "center", lg: "start" }}
            direction="column"
            gap="1rem"
            w="50%"
          >
            <TextComponent
              title="Experence "
              size={{ base: "20px", md: "20px", lg: "24px" }}
              color="white"
              weight="bold"
              align="start"
            />
            <HeadingComponent
              title="12+"
              size={{ base: "30px", md: "30px", lg: "48px" }}
              color="white"
              align="start"
              weight="bold"
            />
            <TextComponent
              title="our technical experence"
              size="14px"
              color="white"
              weight="normal"
              align="start"
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
