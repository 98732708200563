export const testimonial = [
  {
    body: "I was very impressed with the level of expertise and professionalism of the team at Stepping Glory Developer LLC TM. They listened to my needs and created a solution that was exactly what I was looking for. I would highly recommend them to anyone looking for a technical agency",
    author: "John Smith",
    role: " CEO of Acme Corporation",
  },
  {
    body: "I was really happy with the work that SteppingGlory Developer LLC TM did on my website. They were very responsive to my feedback and they made sure that I was completely satisfied with the final product. I would definitely use them again in the future",
    author: "Jane Doe",
    role: "Owner of The Bookstore",
  },
  {
    body: "I was struggling to get my business off the ground, but SteppingGlory Developer LLC TM helped me to create a website that was both professional and user-friendly. Their expertise and guidance was invaluable, and I would not have been able to achieve my goals without them",
    author: "Mary Jones",
    role: "Founder of My Company",
  },
  {
    body: "I was really impressed with the speed and efficiency of SteppingGlory Developer LLC TM. They were able to get my website up and running in just a few weeks, and they were always available to answer my questions. I would definitely recommend them to anyone looking for a quick and easy way to create a website",
    author: "Bill Green",
    role: " Owner of Green's Hardware",
  },
  {
    body: "I was very happy with the quality of work that SteppingGlory Developer LLC TM did on my website. They created a website that was both visually appealing and easy to use. I would definitely recommend them to anyone looking for a high-quality website.",
    author: "Susan Brown",
    role: " Owner of The Bakery",
  },
  {
    body: "I was really impressed with the value that SteppingGlory Developer LLC TM provided. They were able to create a website that was both affordable and high-quality. I would definitely recommend them to anyone looking for a great value.",
    author: " David Williams",
    role: "Owner of The Auto Shop",
  },
  {
    body: "I was very happy with the customer service that I received from SteppingGlory Developer LLC TM. They were always available to answer my questions and they were always willing to go the extra mile to make sure that I was happy with my website. I would definitely recommend them to anyone looking for excellent customer service.",
    author: " Sarah Johnson",
    role: "Owner of The Flower Shop",
  },
  {
    body: "I was very impressed with the creativity of the team at SteppingGlory Developer LLC TM. They were able to take my ideas and turn them into a website that was both unique and visually appealing. I would definitely recommend them to anyone looking for a creative website.",
    author: "Tom Davis",
    role: "Owner of The Furniture Store",
  },
  {
    body: "I was very happy with the overall experience that I had with SteppingGlory Developer LLC TM. They were professional, efficient, and they created a website that I am very happy with. I would definitely recommend them to anyone looking for a website.",
    author: "Michael Scott",
    role: "CEO of Dunder Mifflin Paper Company",
  },
  {
    body: "I was very happy with the results that I received from SteppingGlory Developer LLC TM. They were able to create a website that helped me to increase my sales and improve my bottom line. I would definitely recommend them to anyone looking for a website that can help them to grow their business",
    author: "Pam Beesly",
    role: "Receptionist at Dunder Mifflin Paper Company",
  },
];
