import { Avatar, Flex } from "@chakra-ui/react";
import React, { useRef } from "react";
import TextComponent from "../ui/Text";
import HeadingComponent from "../ui/Heading";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import "swiper/css/navigation";

// import required modules
import { Pagination, Autoplay } from "swiper";
import { testimonial } from "../data";

export default function Testimony() {
  return (
    <Swiper
      style={{
        "--swiper-pagination-color": "#FFBA08",
        "--swiper-pagination-bullet-inactive-color": "#999999",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "16px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
      }}
      navigation={true}
      modules={[Autoplay, Navigation]}
      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
      }}
      className="mySwiper"
    >
      {testimonial.map((el) => (
        <SwiperSlide>
          <Flex
            w="100%"
            align="center"
            my="4rem"
            py="3rem"
            direction="column"
            gap="1rem"
          >
            <TextComponent
              title="Testimony"
              color="brand.900"
              align="center"
              weight="bold"
              size="24px"
            />
            <Flex align="center" justify="center" w="100%">
              <Avatar />
            </Flex>
            <TextComponent
              color="#000"
              title={el.body}
              weight="normal"
              size={{ base: "20px", md: "20px", lg: "24px" }}
              align="center"
              width={{ base: "100%", md: "100%", lg: "60%" }}
              mx="auto"
            />
            <HeadingComponent
              size="16px"
              weight="bold"
              color="#000"
              title={el.author}
            />
            <HeadingComponent
              size="16px"
              weight="normal"
              color="#000"
              title={el.role}
            />
          </Flex>
        </SwiperSlide>
      ))}
      <div className="prev" />
      <div className="next" />
    </Swiper>
  );
}
