import { Box, Flex } from "@chakra-ui/react";
import ImageComponent from "../ui/Image";

import benefitImg from "../images/half.jpg";
import benefitImg1 from "../images/image.jpg";
import bar from "../images/bar.png";
import bar1 from "../images/bar1.png";

import TextComponent from "../ui/Text";
import HeadingComponent from "../ui/Heading";

export default function Benefit() {
  return (
    <Flex
      align="center"
      p={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      justify="space-between"
      gap={{ base: "2rem", md: "2rem", lg: "0rem" }}
      wrap="wrap"
    >
      <Flex
        w={{ base: "100%", md: "100%", lg: "50%" }}
        align="center"
        gap="1rem"
        display={{ base: "none", md: "none", lg: "flex" }}
      >
        <Box w="25%">
          <ImageComponent
            alt="benefit"
            src={benefitImg}
            height={{ base: "30vh", md: "30vh", lg: "636px" }}
          />
        </Box>
        <Box w="75%">
          <ImageComponent
            alt="benefit"
            src={benefitImg1}
            height={{ base: "30vh", md: "30vh", lg: "636px" }}
          />
        </Box>
      </Flex>
      <Flex
        p={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
        height="636px"
        w={{ base: "100%", md: "100%", lg: "50%" }}
        align="start"
        justify="center"
        gap="1rem"
        direction="column"
      >
        <TextComponent
          title="Here’s why"
          color="brand.900"
          align="start"
          weight="bold"
          size="20px"
        />
        <HeadingComponent
          title="Benefits of choosing our agency"
          size={{ base: "20px", md: "20px", lg: "40px" }}
          color="#000"
          weight="bold"
          align="start"
          width="75%"
        />
        <TextComponent
          color="#000"
          title="If you are looking for an agency that can help you achieve your goals, then we encourage you to contact us today. We would be happy to discuss your needs and how we can help you succeed."
          weight="normal"
          size="20px"
          align="start"
        />
        <HeadingComponent
          title="We have a team of experienced professionals who are experts in their field"
          size="20px"
          color="#000"
          weight="medium"
          align="start"
        />
        {false && (
          <Box w="100%">
            <ImageComponent height="20px" src={bar1} alt="bar" />
          </Box>
        )}
        <HeadingComponent
          title="We use the latest technologies and best practices to create high-quality solutions."
          size="20px"
          color="#000"
          weight="medium"
          align="start"
        />
        {false && (
          <Box w="100%">
            <ImageComponent height="20px" src={bar} alt="bar" />
          </Box>
        )}
      </Flex>
    </Flex>
  );
}
