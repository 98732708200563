import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import TextComponent from "../ui/Text";
import HeadingComponent from "../ui/Heading";
import ImageComponent from "../ui/Image";

import web from "../images/website.jpg";
import app from "../images/app.jpg";
import digital from "../images/digital.jpg";
import { useNavigate } from "react-router-dom";

export default function OurServices() {
  const navigate = useNavigate();
  return (
    <Flex
      w="100%"
      py="4rem"
      px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      align="center"
      direction="column"
      gap={{ base: "0.5rem", md: "0.5rem", lg: "2rem" }}
      my="4rem"
      bg="#f5f5f5"
    >
      <TextComponent
        title="Our Services"
        size="24px"
        color="brand.900"
        align="center"
      />
      <HeadingComponent
        title="We provide tech Solutions that speaks value "
        size={{ base: "16px", md: "16px", lg: "48px" }}
        weight="bold"
        color="#000"
        align="center"
      />
      <Flex
        w="100%"
        gap="1rem"
        wrap="wrap"
        align="center"
        justify="space-between"
      >
        <Box
          onClick={() => navigate("/services")}
          w={{ base: "100%", md: "100%", lg: "30%" }}
          position="relative"
        >
          <ImageComponent
            alt="website development"
            height={{ base: "30vh", md: "30vh", lg: "314px" }}
            src={web}
          />
          <Flex
            w="100%"
            height={{ base: "30vh", md: "30vh", lg: "314px" }}
            position="absolute"
            top="0"
            bg="rgba(192, 86, 29, 0.5)"
            align="center"
            justify="center"
            p="1rem"
          >
            <HeadingComponent
              title="Website Development"
              size={{ base: "30px", md: "30px", lg: "48px" }}
              weight="bold"
              align="center"
              color="white"
              w="80%"
              mx="auto"
            />
          </Flex>
        </Box>
        <Box
          onClick={() => navigate("/services")}
          w={{ base: "100%", md: "100%", lg: "30%" }}
          position="relative"
        >
          <ImageComponent
            alt="mobile app development"
            height={{ base: "30vh", md: "30vh", lg: "314px" }}
            src={app}
          />
          <Flex
            w="100%"
            height={{ base: "30vh", md: "30vh", lg: "314px" }}
            position="absolute"
            top="0"
            bg="rgba(192, 86, 29, 0.5)"
            align="center"
            justify="center"
          >
            <HeadingComponent
              title="Mobile Apps
              Development"
              color="white"
              size={{ base: "30px", md: "30px", lg: "48px" }}
              weight="bold"
              align="center"
              w="80%"
              mx="auto"
            />
          </Flex>
        </Box>
        <Box
          onClick={() => navigate("/services")}
          w={{ base: "100%", md: "100%", lg: "30%" }}
          position="relative"
        >
          <ImageComponent
            alt="digital marketing"
            height={{ base: "30vh", md: "30vh", lg: "314px" }}
            src={digital}
          />
          <Flex
            w="100%"
            height={{ base: "30vh", md: "30vh", lg: "314px" }}
            position="absolute"
            top="0"
            bg="rgba(192, 86, 29, 0.5)"
            align="center"
            justify="center"
          >
            <HeadingComponent
              title="Digital Marketing"
              color="white"
              size={{ base: "30px", md: "30px", lg: "48px" }}
              weight="bold"
              align="center"
              w="80%"
              mx="auto"
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}
