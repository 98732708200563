import { Flex } from "@chakra-ui/react";
import React from "react";
import TextComponent from "../ui/Text";
import HeadingComponent from "../ui/Heading";

export default function OurServices() {
  return (
    <Flex align="center" py="3rem" px="2.5rem" direction="column" gap="1rem">
      <TextComponent
        title="Our Services"
        color="brand.900"
        align="center"
        weight="bold"
        size="24px"
      />
      <HeadingComponent
        title="We provide tech Solutions that speaks value "
        size={{ base: "20px", md: "20px", lg: "40px" }}
        color="#000"
        weight="bold"
        align="center"
        width={{ base: "90%", md: "90%", lg: "70%" }}
      />
      <Flex
        w="90%"
        py="3rem"
        mx="auto"
        wrap="wrap"
        align="center"
        justify="space-between"
        gap={{ base: "2rem", md: "2rem", lg: "0rem" }}
      >
        <Flex
          height={{ base: "100%", md: "100%", lg: "300px" }}
          w={{ base: "100%", md: "100%", lg: "30%" }}
          align="center"
          direction="column"
          gap="1rem"
        >
          <HeadingComponent
            align="center"
            size="24px"
            weight="bold"
            title="Web Development"
            color="#000"
          />
          <TextComponent
            color="#000"
            title="We build websites that are both beautiful and functional. We use the latest technologies and best practices to create websites that are easy to use and navigate. We also offer a wide range of customization options, so you can create a website that reflects your brand and personality."
            weight="normal"
            size="16px"
            align="center"
            width="80%"
            mx="auto"
          />
        </Flex>

        <Flex
          height={{ base: "100%", md: "100%", lg: "300px" }}
          w={{ base: "100%", md: "100%", lg: "30%" }}
          align="center"
          direction="column"
          gap="1rem"
        >
          <HeadingComponent
            align="center"
            size="24px"
            weight="bold"
            title="Mobile App"
            color="#000"
          />
          <TextComponent
            color="#000"
            title="We develop mobile apps that are both user-friendly and engaging. We use the latest technologies and best practices to create apps that are optimized for both iOS and Android devices. We also offer a wide range of customization options, so you can create an app that meets your specific needs."
            weight="normal"
            size="16px"
            align="center"
            width="80%"
            mx="auto"
          />
        </Flex>

        <Flex
          height={{ base: "100%", md: "100%", lg: "300px" }}
          w={{ base: "100%", md: "100%", lg: "30%" }}
          align="center"
          direction="column"
          gap="1rem"
        >
          <HeadingComponent
            align="center"
            size="24px"
            weight="bold"
            title="Digital Marketing"
            color="#000"
          />
          <TextComponent
            color="#000"
            title="We help businesses reach their target audience through a variety of digital marketing channels, including search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, and email marketing. We develop customized marketing campaigns that are designed to achieve your specific goals. "
            weight="normal"
            size="16px"
            align="center"
            width="80%"
            mx="auto"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
