import { Box, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ImageComponent from "../ui/Image";
import heroImg from "../images/home.jpg";
import TextComponent from "../ui/Text";
import HeadingComponent from "../ui/Heading";
import ButtonComponent from "../ui/Button";

export default function Hero() {
  const navigate = useNavigate();
  return (
    <Box position="relative" w="100%">
      <ImageComponent
        src={heroImg}
        height={{ base: "50vh", md: "50vh", lg: "90vh" }}
        alt="hero image"
      />
      <Box
        position="absolute"
        top="0"
        left="0"
        w="100%"
        height={{ base: "50vh", md: "50vh", lg: "90vh" }}
        bg="rgba(0,0,0,0.4)"
      ></Box>
      <Flex
        align="center"
        justify="center"
        position="absolute"
        top="0%"
        height="100%"
        w="100%"
      >
        <Flex align="center" direction="column" gap="2rem">
          <Flex
            align="center"
            gap="1rem"
            mt={{ base: "7rem", md: "7rem", lg: "0rem" }}
            direction="column"
          >
            <TextComponent
              title="WELCOME TO STEPPINGGLORY DEVELOPER LLC"
              color="white"
              weight="bold"
              align="center"
              size={{ base: "20px", md: "20px", lg: "30px" }}
            />
            <HeadingComponent
              title="Technical Consulting Agency with Customer Focus"
              weight="bold"
              size={{ base: "20px", md: "20px", lg: "64px" }}
              color="white"
              width="70%"
              mx="auto"
            />
          </Flex>
          <ButtonComponent
            onClick={() => navigate("/services")}
            title="Explore"
            size="lg"
            weight="bold"
            color="brand.900"
            bg="#fff"
          />
        </Flex>
      </Flex>
    </Box>
  );
}
